@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@700&display=swap');

$primary-color: #73002E;
$secondary-color: #910039;
$default-color: #383838;
$white: #ffffff;

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  &::after {
    display: block;
    overflow: hidden;
    width: 0;
    height: 0;
    content: url('../../dist/search-icon.e0b687d6.svg') url('../../dist/search-icon-hover.706a6641.svg') url('../../dist/search-icon-collapse.c9aef4fb.svg') url('../../dist/hamburger-icon.64a482e6.svg') url('../../dist/hamburger-icon-collapse.09559699.svg');
   }
}

.clear-fix {
  clear: both;
}

.desktop-hidden {
  @media only screen and (min-width: 768px) {
    display: none;
  }
}

.global-header-wrap {
  padding: 30px 0;
  background-color: $secondary-color;
  display: flex;
  position: relative;
  @media only screen and (max-width: 1240px) {
    display: inherit;
  }
  @media only screen and (max-width: 768px) {
    padding: 15px 0;
  }

  .column-middle-flex, .global-menu-align {
    flex: 1;
  }
  .global-menu-align {
    max-width: 380px;
    @media only screen and (max-width: 1240px) {
      float: right;
    }
  }
  .column-middle-flex {
    z-index: 999;
    @media only screen and (max-width: 1240px) {
      display: none;
    }
  }

  .logo-wrap {
    padding: 0 60px;
    @media only screen and (max-width: 768px) {
      padding: 0 15px;
    }
    .site-logo {
        height: 83px;
        width: 142px;
        background-image: url(/images/header-logo-mobile.svg);
        text-indent: -9999px;
        overflow: hidden;
        display: inline-block;
        background-repeat: no-repeat;
        background-size: contain;
        vertical-align: middle;
        color: $white;
        @media only screen and (max-width: 768px) {
          background-image: url(/images/header-logo-mobile.svg);
          height: 56px;
          width: 97px;
        }
    }
    @media only screen and (max-width: 1240px) {
      float: left;
    }
  }
}

.global-menu-align {
  float: right;
  position: relative;
  @media only screen and (max-width: 768px) {
    position: initial;
  }
.global-menu-wrap {
  background-color: $primary-color;
  padding: 0 15px;
  border-radius: 60px 0 0 60px;
  position: relative;
  z-index: 998;
  @media only screen and (max-width: 768px) {
    position: initial;
  }
  .global-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      display: inline;
      margin-right: 30px;
      a {
        color: $white;
        text-decoration: none;
        font-size: 16px;
        line-height: 5.12;
        &:hover {
          text-decoration: underline;
          text-decoration-thickness: 2px;
        }
        &:focus-visible {
          outline: 1px solid $white;
          box-shadow: 0 0 0px 1px #1D5FCC;
          border-radius: 3px;
        }
      }
      &:first-child a {
        height: 54px;
        width: 54px;
        background-image: url(../../dist/search-icon.e0b687d6.svg);
        text-indent: -9999px;
        overflow: hidden;
        display: inline-block;
        background-repeat: no-repeat;
        background-size: cover;
        vertical-align: middle;
        &:hover {
          background-image: url(../../dist/search-icon-hover.706a6641.svg);
        }
        &.navsearchclose {
          background-image: url(../../dist/search-icon-collapse.c9aef4fb.svg);
        }
        @media only screen and (max-width: 768px) {
          height: 38px;
          width: 38px;
        }
      }
      &:last-child {
        margin-right: 0;
      }
      &:last-child a {
        height: 54px;
        width: 54px;
        background-image: url(../../dist/hamburger-icon.64a482e6.svg);
        text-indent: -9999px;
        overflow: hidden;
        display: inline-block;
        background-repeat: no-repeat;
        background-size: cover;
        vertical-align: middle;
        &.navhamburgerclose {
          background-image: url(../../dist/hamburger-icon-collapse.09559699.svg);
        }
        @media only screen and (max-width: 768px) {
          height: 38px;
          width: 38px;
        }
      }
      @media only screen and (max-width: 768px) {
        display: none;
        &:first-child, &:last-child {
          display: inline-block;
          height: 38px;
          width: 38px;
          margin: 12px 0;
        }
      }
    }
  }
}
.hamburgercontent, .searchcontent {
  display: none;
  color: $white;
  background-color: $primary-color;
  padding: 60px 15px 100px;
  border-radius: 0 0 0 60px;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  z-index: 1;
  @media only screen and (max-width: 768px) {
    top: 100%;
    border-radius: 0;
    padding: 15px 15px 100px;
  }
  
  .hamburgercontent {
    @media only screen and (max-width: 768px) {
      min-height: 100vh;
    }
  }

  .global-list-flex-row {
    padding: 0 65px;
    @media only screen and (max-width: 1240px) {
      padding: 0;
      display: flex;
      gap: 60px;
      border-top: solid 1px #ffffff;
      margin-top: 60px;
      padding-top: 0;
    }
    .global-list-flex-column {
      margin-top: 30px;
      @media only screen and (max-width: 1240px) {
        margin-top: 0;
        flex: 1;
        .quick-links-heading {
          font-size: 18px;
        }
      }
      .global-list-items {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
          margin-bottom: 15px;
          a {
            color: $white;
            font-size: 18px;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
            @media only screen and (max-width: 1240px) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .quick-links-heading {
    font-family: 'Roboto Slab', serif;
    font-size: 24px;
    margin-bottom: 19px;
  }
}

.searchcontent {
  @media only screen and (max-width: 768px) {
    padding: 0 !important;
  }
}

.searchcontent {
  padding: 60px 30px;
}

.sr-only{
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
}

.custom-search {
  position: relative;
  .custom-search-input {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 10px 40px 10px 20px; 
    line-height: 1;
    box-sizing: border-box;
    outline: none;
    font-size: 18px;
    font-family: "Open Sans", sans-serif;
    @media only screen and (max-width: 768px) {
      border-radius: 0;
      padding: 16px 20px 16px 60px;
    }
  }
  .custom-search-botton {
    position: absolute;
    right: 3px; 
    top: 8px;
    bottom: 3px;
    border: 0;
    background: $default-color;
    color: #fff;
    outline: none;
    margin: 0;
    padding: 0 10px;
    border-radius: 100px;
    z-index: 2;
    @media only screen and (max-width: 768px) {
      top: 9px;
      left: 15px;
    }
  }
  .custom-search-botton {
    background-image: url(/images/submit-arrow.svg);
    border:0;
    display:block;
    height: 29px;
    width: 29px;
    text-indent: -9999px;
    overflow: hidden;
    cursor: pointer;
    @media only screen and (max-width: 768px) {
      background-image: url(/images/search-icon-mobile.svg);
      height: 38px;
      width: 38px;
      background-color: transparent;
    }
    &:focus-visible {
      outline: 1px solid $white;
      box-shadow: 0 0 0px 2px #1D5FCC;
    }
  }
}
}
